<template>
  <div class="tw-flex tw-w-full tw-h-auto" style="background: white">
    <v-col sm="12" lg="4" class="tw-hidden left-side lg:tw-flex">
      <OnboardingLeft />
    </v-col>
    <v-col sm="12" lg="8" class="right-side scroll" style="position: relative">
      
      <v-col sm="12" class="right-side-body">
        <img src="@/assets/logos/blue.png" alt="logo" style="height: 34px; width: 136px;" class="mb-3 mt-6 img-logo">

        <p class="welcome tw-mt-0">Verification</p>
        <p class="admin tw-mt-5">
          Let’s get your unique identifier
        </p>
        <p class="admin tw-mt-2">
          details and you'd be set to go.
        </p>
        
        
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(saveData)">
            <div class="shift-button-down">
            <v-row class="form">
              <div  class="tw-flex tw-flex-row  tw-w-full tw-flex-wrap tw-py-3">
                <v-col class="tw-w-full lg:tw-w-1/2">
                  <label  class="label pb-2">CAC Certificate</label>
                  <div class="input-upload-box">
                    <div class="img mt-3">
                      <v-progress-circular :size="100" :width="3" color="blue" indeterminate style="position: absolute;" v-if="cacImageLoading"></v-progress-circular>
                      <img :src="cImg" alt="" v-if="cImg" class="imgg" @click="onPickCacFile()">
                      <img src="@/assets/plus.svg" alt="" v-else @click="onPickCacFile()">
                    </div>
                    <button type="button" @click="onPickCacFile()" class="input-upload-box-btn">
                      upload
                    </button>
                    <input type="file" ref="CAC" accept="image/*,.doc,.docx,.pdf" style="display:none" @change="onCACImagePicked">
                  </div>
                </v-col>
                <v-col class="tw-w-full lg:tw-w-1/2">
                  <label  class="label pb-2">Utility Bill</label>
                  <div class="input-upload-box">
                    <div class="img mt-3">
                      <v-progress-circular :size="100" :width="3" color="blue" indeterminate style="position: absolute;" v-if="utilityImageLoading"></v-progress-circular>
                      <img :src="utilityImg" alt="" v-if="utilityImg" class="imgg" @click="onPickUtilityFile()">
                      <img src="@/assets/plus.svg" alt="" v-else @click="onPickUtilityFile()">
                    </div>
                    <button type="button" @click="onPickUtilityFile()" class="input-upload-box-btn">
                      upload
                    </button>
                    <input type="file" ref="UTILITY" accept="image/*,.doc,.docx,.pdf" style="display:none" @change="onUtilityImagePicked">
                  </div>
                </v-col>
              </div>


              <v-col lg="6" sm="12" cols="12" class="mb-md-4">
                <ValidationProvider name="CAC Registration Number" rules="required" v-slot="{ classes, errors }">
                  <label  class="label">CAC Registration Number</label>
                  <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                    <v-text-field  placeholder="RC16595111" solo hide-details type="text" v-model="cac" required class="tw-mt-2"></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>

              <v-col lg="6" sm="12" cols="12" class="mb-md-4">
                <ValidationProvider name="TAX ID Number" rules="required" v-slot="{ classes, errors }">
                  <label  class="label">TAX ID Number</label>
                  <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                    <v-text-field  placeholder="01234567-0001" solo hide-details type="text" v-model="tin"  required></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>

              </v-col>

<!--              <v-col lg="6" sm="12" cols="12" class="mb-md-4">-->
<!--                <ValidationProvider name="Representative's Full Name" rules="required" v-slot="{ classes, errors }">-->
<!--                  <label  class="label">Representative's Full Name</label>-->
<!--                  <div class="tw-pt-1 mb-1 mt-2" :class="classes">-->
<!--                    <v-text-field  placeholder="John Doe" solo hide-details type="text" v-model="companyRepresentativeFullName" required class="tw-mt-2"></v-text-field>-->
<!--                    <span>{{ errors[0] }}</span>-->
<!--                  </div>-->
<!--                </ValidationProvider>-->
<!--              </v-col>-->

<!--              <v-col lg="6" sm="12" cols="12" class="mb-md-4">-->
<!--                <ValidationProvider name="Representative's Email" rules="required" v-slot="{ classes, errors }">-->
<!--                  <label  class="label">Representative's email</label>-->
<!--                  <div class="tw-pt-1 mb-1 mt-2" :class="classes">-->
<!--                    <v-text-field  placeholder="Don" solo hide-details type="email" v-model="companyRepresentativeEmail"  required></v-text-field>-->
<!--                    <span>{{ errors[0] }}</span>-->
<!--                  </div>-->
<!--                </ValidationProvider>-->

<!--              </v-col>-->
            </v-row>
            </div>
        <div class="btn-div tw-flex lg:tw-justify-end tw-justify-center">
          <router-link :to="{ name: 'FleetManagementOnboardingAddress' }" class="btn-div-text">
            <img src="../../assets/arrow-back.svg" alt="" class="btn-div-img"> 
            <span> 
              Previous  
            </span>
          </router-link>

          <button class="btn-div-text" :disabled="!formIsValid" @click.prevent="saveData">
             <span>
              Account Details
            </span>
            <img src="../../assets/arrow_forward.svg" alt="" class="btn-div-img">
          </button>
        </div>
          </form>
        </validation-observer>
      </v-col>

    </v-col>
  </div>
</template>

<script>
  import OnboardingLeft from './OnboardingLeft'
  import check from '@/assets/thumbs.svg'
  import { uploadToCloudinary} from '@/services/api/APIService'

  export default {
    name: "CorporateAgentOnboardingVerification",
    components: {
      OnboardingLeft

    },
    data() {
      return {
        dialog: false,
        loading: false,
        check,
        value : false, 
        confirmValue : false,
        tin: '',
        cac: '',
        companyRepEmail: '',
        companyRepPhone: '',
        companyRepresentativeFullName: '',
        companyRepresentativeEmail : '',
        cImg: '',
        cacImage: '',
        cacImageLoading: false,
        cacImageLink: '',
        utilityImg: '',
        utilityImage: '',
        utilityImageLoading: false,
        utilityImageLink: '',

      }
    },
    computed: {
      storedData() {
        return JSON.parse(sessionStorage.getItem('corporateOrganizationData'))
      },
      formIsValid() {
        return this.tin
            && this.cac

            && this.utilityImageLink
            && this.cacImageLink
      }
    },
    methods:{
      validRc(rc){
        let regex = /^\(?([a-zA-Z0-9]{2,})$/;
        return !!(rc.length === 10 && rc.match(regex) && rc.startsWith('RC'));
      },

      validPhoneNumber(tel) {
        if(tel.length > 6) {
          return true;
        }
        else {
          this.$displaySnackbar({
            message: "Phone number not valid e.g 2348000000000",
            success: false,
          });
          return false;
        }
      },
      validEmail(email){
        let valid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (email.match(valid)){
          return true
        }
        else {
          this.$displaySnackbar({
            message: "Email not valid e.g example@company.com",
            success: false,
          });
          return false;
        }
      },
    
      validTin(tin){
        if (tin.length === 13){
          return true
        }
        else {
          this.$displaySnackbar({message: 'Not a valid tin number e.g 01234567-0001'})
        }
      },
      clearSession() {
        sessionStorage.removeItem('data')
      },
      toggleModal(){
        this.dialog = !this.dialog
      },

      onPickUtilityFile() {
        this.$refs.UTILITY.click()
      },
      onPickCacFile() {
        this.$refs.CAC.click()
      },
      onUtilityImagePicked(event) {
        const files = event.target.files[0]
        try {
          console.log(files)
          this.utilityImageLoading = true

          const fileReader =  new FileReader()
          fileReader.addEventListener('load', async () => {
            this.utilityImg = fileReader.result
            this.utilityImage = files.name

            let newData = {
              file: this.utilityImg,
              upload_preset: process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET
            }
            const response = await uploadToCloudinary(newData)
            this.utilityImageLink = response.data.url
            this.utilityImageLoading = false

          })
          fileReader.readAsDataURL(files)

        } catch (error) {
          console.log(error.response);
        }

      },
      onCACImagePicked(event) {
        const files = event.target.files[0]
        try {
          console.log(files)
          this.cacImageLoading = true
          const fileReader =  new FileReader()
          fileReader.addEventListener('load', async () => {
            this.cImg = fileReader.result
            this.cacImage = files.name

            let newData = {
              file: this.cImg,
              upload_preset: process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET
            }
            const response = await uploadToCloudinary(newData)
            console.log(response);
            this.cacImageLink = response.data.url
            this.cacImageLoading = false
          })
          fileReader.readAsDataURL(files)
        } catch (error) {
          console.log(error.response);
        }

      },
      async saveData() {
        if (!this.validRc(this.cac)){
          return this.$displaySnackbar({ message: "Not a valid RC Number", success: false })
        }
        if ( this.validTin(this.tin)){
          let userData = JSON.parse(sessionStorage.getItem('userData'))
          let data = {
            ...this.storedData,
            cacRegistrationNumber: this.cac,
            taxId: this.tin,
            cacCertificate: this.cacImageLink,
            utilityBill : this.utilityImageLink,
            companyEmail: userData.email,
          }
          sessionStorage.setItem('corporateOrganizationData', JSON.stringify(data))
          await this.$router.replace({name: 'FleetManagementOnboardingAccountDetail'})
        }
      },
      fillFields(){
        let data = JSON.parse(sessionStorage.getItem('corporateOrganizationData'))
        if (data){
          this.tin = data.taxId
          this.cac = data.cacRegistrationNumber
          this.cacImageLink = data.cacCertificate
          this.cImg = data.cacCertificate
          this.utilityImageLink = data.utilityBill
          this.utilityImg = data.utilityBill
        }
      },
      getNumber(number){
        this.companyRepPhone = number
      },
     
    },
    mounted() {
      this.fillFields()
    }
  }
</script>

<style lang="scss" scoped>
button:disabled,
button[disabled]{
  opacity: 0.7;
}

.left-side{
  width: 100%;
  min-height: 100vh;
  background-image: url('../../assets/onboarding-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 0!important;
  color: #ffffff;

  @media screen and (max-width: 1024px){
    display: none;
  }
}

.right-side-body{
  width: 100%;
  padding:7rem;
  @media screen and (min-width: 1440px) {
    padding-top: 4.25rem;
  }
  @media screen and (max-width: 1440px ){
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 1366px ) and (min-width: 1025px){
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 1024px ){
    padding: 1.5rem;
  }
}
.right-side-body > .img-logo{
  @media screen and (min-width: 1024px){
    display: none;
  }
}
  .scroll {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 99vh;
    height: 98vh;
  }

  .scroll::-webkit-scrollbar {
    display: none;
  }

.right-side-body .welcome {
  font-family: "Inter", serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #004AAD;
  @media screen and (max-width: 1024px){
    margin-top: 50px;
  }
}

.right-side-body .admin {
  width: 355px;
  font-family: "Inter", serif!important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #828282;
}


.label {
  font-family: "Inter",serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
}

.btn-div{
  margin-top: 52px;
  @media screen and (max-width: 1023px){
    margin-top: 25px;
  }
}

.btn-div .btn-div-text:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  width: 161px;
  border-radius: 10px;
  background: white;
  color: #004AAD;
  border: 1px solid #004AAD;
  .btn-div-img {
    margin-right: 5px;
  }
}

.btn-div .btn-div-text:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 35px;
  padding: 10px 30px;
  width: 200px;
  border-radius: 10px;
  background: #004AAD;
  color: white;
  .btn-div-img {
    margin-left: 5px;
  }
}

.btn-div .btn-div-btn {
  margin-left: 25px;
  // padding: 10px 30px;
  width: 161px;
  
  border-radius: 10px;
  background: #004AAD;
  color: white;
  span {
    display: flex; 
    align-items: center; 
    justify-content: center;

  }
  .btn-div-img {
    margin-left: 5px;
  }
}

.form {
  margin-top: 46px;
}

.input-upload-box{
  display: flex;
  align-items: flex-end;
  margin-top: 5px;
  .img {
    margin-right: 10px;
    width: 72px;
    height: 72px;
    border: 1px solid #BDBDBD;
    position: relative;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .imgg {
      width: auto;
      height: 100%;
      border-radius: 10px;
    }
  }
  .input-upload-box-btn {
    color: #004AAD;
  }
}

.form {
  margin-top: 37px;
  @media screen and (max-width: 1024px) {
    margin-top: 60px;
  }
}
::v-deep .v-application--is-ltr .v-text-field .v-input__prepend-inner {
  padding-right: 0 !important;
}
.shift-button-down{
  min-height: 55vh;
}
</style>